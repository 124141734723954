import React, { useState, useCallback, useEffect } from "react";
import { BrowserRouter, Route, Routes } from "react-router-dom";
import { ThemeProvider } from "@mui/material";

import Navbar from "./components/Navbar";
import ScrollToTop from "./components/ScrollToTop";
import Home from "./pages/Home";
import Sites from "./pages/Sites";
import Auth from "./pages/Auth";
import User from "./pages/User";
import Submit from "./pages/Submit";
import { theme } from "./theme";
import { AuthContext } from "./contexts/auth-context";

let logoutTimer;

const App = () => {
    const [token, setToken] = useState(null);
    const [userId, setUserId] = useState(null);
    const [username, setUsername] = useState(null);
    const [userEmail, setUserEmail] = useState(null);
    const [tokenExpirationDate, setTokenExpirationDate] = useState(null);

    const login = useCallback(
        (uid, username, userEmail, token, expirationDate) => {
            setToken(token);
            setUserId(uid);
            setUsername(username);
            setUserEmail(userEmail);
            const newTokenExpirationDate =
                expirationDate || new Date(new Date().getTime() + 3600000);
            setTokenExpirationDate(newTokenExpirationDate);
            localStorage.setItem(
                "userData",
                JSON.stringify({
                    userId: uid,
                    username: username,
                    userEmail: userEmail,
                    token: token,
                    expiration: newTokenExpirationDate.toISOString(),
                })
            );
        },
        []
    );

    const logout = useCallback(() => {
        setToken(null);
        setUserId(null);
        setUsername(null);
        setUserEmail(null);
        localStorage.removeItem("userData");
    }, []);

    useEffect(() => {
        if (token && tokenExpirationDate) {
            const remainingTime =
                tokenExpirationDate.getTime() - new Date().getTime();
            logoutTimer = setTimeout(logout, remainingTime);
        } else {
            clearTimeout(logoutTimer);
        }
    }, [token, logout, tokenExpirationDate]);

    useEffect(() => {
        const storedData = JSON.parse(localStorage.getItem("userData"));
        if (
            storedData &&
            storedData.token &&
            new Date(storedData.expiration) > new Date()
        ) {
            login(storedData.userId, storedData.username, storedData.token);
        }
    }, [login]);

    return (
        <AuthContext.Provider
            value={{
                isLoggedIn: !!token,
                token: token,
                userId: userId,
                username: username,
                userEmail: userEmail,
                login: login,
                logout: logout,
            }}
        >
            <ThemeProvider theme={theme}>
                <BrowserRouter>
                    <div className="body">
                        <Navbar />
                        <ScrollToTop />
                        <Routes>
                            <Route exact path="/" element={<Home />} />
                            <Route exact path="/database" element={<Sites />} />
                            <Route
                                exact
                                path="/auth"
                                element={token ? <User /> : <Auth />}
                            />
                            <Route
                                exact
                                path="/submit"
                                element={token ? <Submit /> : <Auth />}
                            />
                        </Routes>
                    </div>
                </BrowserRouter>
            </ThemeProvider>
        </AuthContext.Provider>
    );
};

export default App;
