import { Box, Card, CircularProgress, Modal, Typography } from "@mui/material";
import { Line } from "react-chartjs-2";
import Chart from "chart.js/auto"; // avoid category scale error

const DateModal = (props) => {
    const options = {
        plugins: {
            legend: {
                display: false,
            },
        },
        scales: {
            x: {
                grid: { display: false },
                title: { display: true, text: "Cal BP" },
            },
            y: { grid: { display: false } },
        },
    };

    return (
        <Modal open={props.open} onClose={props.handleClose}>
            <Box
                sx={{
                    position: "absolute",
                    top: "50%",
                    left: "50%",
                    transform: "translate(-50%, -50%)",
                    width: { xs: "90%", sm: "60%", md: "30%" },
                }}
            >
                <Card sx={{ p: 4 }}>
                    {props.site && (
                        <>
                            <Typography>
                                <span style={{ fontWeight: "bold" }}>
                                    {props.site.Site}
                                </span>
                                &nbsp;
                            </Typography>
                            <Typography mb={2} variant="body2">
                                {props.site.C14Age} +/- {props.site.C14SD}{" "}
                                <sup>14</sup>C BP
                                {props.site.LabNumber &&
                                    ` (` + props.site.LabNumber + `)`}
                            </Typography>
                        </>
                    )}
                    {(props.calData && props.calInfo && (
                        <>
                            <Line
                                data={props.calData}
                                options={options}
                                style={{ marginBottom: "16px" }}
                            />
                            <Typography
                                sx={{ fontWeight: "bold" }}
                            >
                                95.4% Probability
                            </Typography>
                            {props.calInfo &&
                                props.calInfo.map((row) => (
                                    <Typography variant="body2">
                                        {row.interval[1]}-{row.interval[0]} cal
                                        BP ({(row.prob * 100).toFixed(2)}%)
                                    </Typography>
                                ))}
                        </>
                    )) || (
                        <div style={{ textAlign: "center" }}>
                            <CircularProgress />
                        </div>
                    )}
                </Card>
            </Box>
        </Modal>
    );
};

export default DateModal;
