import * as React from "react";
import { useState, useMemo } from "react";
import { Map, Source, Layer, Popup } from "react-map-gl";

import { pink } from "@mui/material/colors";

import { Typography } from "@mui/material";

const SiteMap = props => {
    const [popupInfo, setPopupInfo] = useState(null);

    const geojson = {
        type: "FeatureCollection",
        features: props.data.map(site => (
            {
                type: "Feature",
                geometry: {
                    type: "Point",
                    coordinates: [site.Longitude, site.Latitude]
                },
                properties: {
                    siteName: site.Site,
                    description: site.SiteDescription,
                    reference: site.Reference
                }
            }
        ))
    }

    const layerStyle = {
        id: "layer-sites",
        type: "circle",
        paint: {
            "circle-radius": 4,
            "circle-stroke-width": 1,
            "circle-color": "white",
            "circle-stroke-color": pink[400]
        }
    };

    return (
        <Map
            initialViewState={{
                longitude: -60,
                latitude: -15,
                zoom: 1.5
            }}
            style={{ width: "100%", height: "100%", borderRadius: "1em" }}
            /*mapStyle="https://demotiles.maplibre.org/style.json"*/
            mapStyle="mapbox://styles/mapbox/dark-v10"
            mapboxAccessToken={process.env.REACT_APP_MAPBOX_TOKEN}
            interactiveLayerIds={["layer-sites"]}
            onClick={e => setPopupInfo(e.features[0])}
        >;
            <Source type="geojson" data={geojson}>
                <Layer {...layerStyle} />
            </Source>

            {popupInfo && (
                <Popup
                    anchor="bottom"
                    longitude={Number(popupInfo.geometry.coordinates[0])}
                    latitude={Number(popupInfo.geometry.coordinates[1])}
                    onClose={() => setPopupInfo(null)}
                >
                    <Typography>
                        <strong>{popupInfo.properties.siteName}</strong>
                    </Typography>
                    <Typography variant="body2">
                        {popupInfo.properties.description}
                    </Typography>
                    <Typography variant="body2">
                        {popupInfo.properties.reference}
                    </Typography>
                </Popup>
            )}

        </Map>
    )
}

export default SiteMap;