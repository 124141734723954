import React from "react";
import { useEffect, useState } from "react";
import {
    Button,
    CircularProgress,
    Card,
    Container,
    FormControl,
    Grid,
    InputLabel,
    MenuItem,
    Select,
    Typography,
} from "@mui/material";

import { useHttpClient } from "../hooks/http-hook";
import SiteList from "../components/SiteList";
import Form from "../components/Form";
import SiteMap from "../components/Map";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import { pink } from "@mui/material/colors";
import DateModal from "../components/DateModal";

const Sites = () => {
    const [formState, setFormState] = useState({
        siteName: "",
        minAge: "0",
        maxAge: "6000",
        tradition: "",
        search: "",
    });
    const [sites, setSites] = useState([]);
    const [isLoading, setIsLoading] = useState(false);
    const [maxResults, setMaxResults] = useState(10);
    const [sortBy, setSortBy] = useState("nameAscending");
    const [searched, setSearched] = useState(false);
    const { sendRequest } = useHttpClient();
    const [open, setOpen] = useState(false);
    const [calData, setCalData] = useState(null);
    const [calInfo, setCalInfo] = useState(null);
    const [calSite, setCalSite] = useState(null);
    const [visitedSites, setVisitedSites] = useState({});

    const handleSubmit = async () => {
        // const url = new URL("http://localhost:8000/sites/search");
        const url = new URL("https://api.expandproj.org/sites/search");

        url.searchParams.append("siteName", formState.siteName);
        url.searchParams.append("minAge", formState.minAge);
        url.searchParams.append("maxAge", formState.maxAge);
        url.searchParams.append("tradition", formState.tradition);
        url.searchParams.append("search", formState.search);

        setSearched(true);
        setIsLoading(true);

        const responseData = await sendRequest(url.toString(), "GET", null, {
            "Content-Type": "application/json",
        });

        if (sortBy === "nameAscending") {
            responseData.sort((a, b) =>
                a.Site.toLowerCase() > b.Site.toLowerCase() ? 1 : -1
            );
        } else if (sortBy === "nameDescending") {
            responseData.sort((a, b) =>
                a.Site.toLowerCase() < b.Site.toLowerCase() ? 1 : -1
            );
        } else if (sortBy === "ageAscending") {
            responseData.sort((a, b) =>
                parseInt(a.C14Age) > parseInt(b.C14Age) ? 1 : -1
            );
        } else if (sortBy === "ageDescending") {
            responseData.sort((a, b) =>
                parseInt(a.C14Age) < parseInt(b.C14Age) ? 1 : -1
            );
        }

        setIsLoading(false);
        setSites(responseData);
        setMaxResults(10);
    };

    const inputChangeHandler = (event) => {
        let inputId = event.target.id;
        let inputValue = event.target.value;
        if (inputId.includes("tradition")) {
            inputId = "tradition";
            inputValue = event.target.innerHTML;
        }
        setFormState((prev) => ({ ...prev, [inputId]: inputValue }));
    };

    const sliderChangeHandler = (event) => {
        let minValue = event.target.value[0];
        let maxValue = event.target.value[1];
        if (maxValue - minValue >= 1000)
            setFormState((prev) => ({
                ...prev,
                minAge: minValue,
                maxAge: maxValue,
            }));
    };

    const clearForm = () => {
        setFormState({
            siteName: "",
            minAge: "0",
            maxAge: "6000",
            tradition: "",
            search: "",
        });
    };

    const showMoreHandler = () => {
        setMaxResults(maxResults + 10);
    };

    const sortHandler = (event) => {
        setSortBy(event.target.value);
    };

    const sortResults = () => {
        const sorted = [...sites];
        if (sortBy === "nameAscending") {
            sorted.sort((a, b) =>
                a.Site.toLowerCase() > b.Site.toLowerCase() ? 1 : -1
            );
        } else if (sortBy === "nameDescending") {
            sorted.sort((a, b) =>
                a.Site.toLowerCase() < b.Site.toLowerCase() ? 1 : -1
            );
        } else if (sortBy === "ageAscending") {
            sorted.sort((a, b) =>
                parseInt(a.C14Age) > parseInt(b.C14Age) ? 1 : -1
            );
        } else if (sortBy === "ageDescending") {
            sorted.sort((a, b) =>
                parseInt(a.C14Age) < parseInt(b.C14Age) ? 1 : -1
            );
        }
        setSites(sorted);
    };

    useEffect(() => {
        sortResults();
    }, [sortBy]);

    const handleCalibration = async (site) => {
        setCalData(null);
        setCalInfo(null);
        setCalSite(site);

        setOpen(true);

        if (visitedSites[site._id]) {
            setCalData(visitedSites[site._id].calData);
            setCalInfo(visitedSites[site._id].calInfo);
            return;
        }

        // let url = new URL("http://localhost:8000/dates/calibrate");
        let url = new URL("https://api.expandproj.org/dates/calibrate");
        url.searchParams.append("c14age", site.C14Age);
        url.searchParams.append("c14sd", site.C14SD);

        const responseData = await sendRequest(url.toString(), "GET", null, {
            "Content-Type": "application/json",
        });

        const calDate = responseData.calDate;
        setCalInfo(responseData.hpdData.intervals);

        let newCalData = {
            labels: calDate.map((row) => row[0]),
            datasets: [
                {
                    label: "Calibrated Date",
                    data: calDate.map((row) => row[1]),
                    fill: false,
                    borderColor: pink[400],
                    borderWidth: 2,
                    pointRadius: 0,
                },
                {
                    label: "95% probability",
                    data: responseData.hpdData.prDens.map((row) => row[1]),
                    fill: true,
                    backgroundColor: "rgba(255, 99, 132, 0.2)",
                    borderWidth: 0,
                    pointRadius: 0,
                },
            ],
        };

        setCalData(newCalData);

        setVisitedSites((prev) => ({
            ...prev,
            [site._id]: {
                calData: newCalData,
                calInfo: responseData.hpdData.intervals,
            },
        }));
    };

    return (
        <React.Fragment>
            <DateModal
                open={open}
                handleClose={() => setOpen(false)}
                calData={calData}
                calInfo={calInfo}
                site={calSite}
            />
            <Grid container className="page-image"></Grid>
            <Container sx={{ position: "relative", zIndex: 2, mt: 2 }}>
                <Card sx={{ p: 4 }}>
                    <Grid container spacing={2}>
                        <Grid item xs={12} md={6}>
                            <Form
                                onInputChange={inputChangeHandler}
                                onSliderChange={sliderChangeHandler}
                                formState={formState}
                                onButtonClick={clearForm}
                                onSubmit={handleSubmit}
                            />
                        </Grid>
                        <Grid item xs={12} md={6} sx={{ minHeight: "20vh" }}>
                            <SiteMap data={sites} />
                        </Grid>
                    </Grid>
                </Card>

                <Grid container sx={{ justifyContent: "center", mt: 2, mb: 2 }}>
                    {isLoading && <CircularProgress />}
                </Grid>
                {!isLoading && searched && (
                    <React.Fragment>
                        <Grid
                            container
                            spacing={2}
                            sx={{ justifyContent: "center", mb: 4 }}
                        >
                            <Grid
                                item
                                xs={12}
                                sm={6}
                                sx={{
                                    textAlign: "center",
                                    mt: "auto",
                                    mb: "auto",
                                }}
                            >
                                <Typography variant="h5">
                                    <strong>{sites.length}</strong> results
                                    found
                                </Typography>
                            </Grid>
                            <Grid item xs={12} sm={6}>
                                <FormControl fullWidth>
                                    <InputLabel id="sort-select-label">
                                        Sort by
                                    </InputLabel>
                                    <Select
                                        size="small"
                                        label="Sort by"
                                        labelId="sort-select-label"
                                        value={sortBy}
                                        onChange={sortHandler}
                                    >
                                        <MenuItem value="nameAscending">
                                            Name (ascending)
                                        </MenuItem>
                                        <MenuItem value="nameDescending">
                                            Name (descending)
                                        </MenuItem>
                                        <MenuItem value="ageAscending">
                                            Age (ascending)
                                        </MenuItem>
                                        <MenuItem value="ageDescending">
                                            Age (descending)
                                        </MenuItem>
                                    </Select>
                                </FormControl>
                            </Grid>
                        </Grid>
                    </React.Fragment>
                )}
                {!isLoading && sites.length > 0 && (
                    <SiteList
                        sites={sites.slice(0, maxResults)}
                        handleCalibration={handleCalibration}
                    />
                )}
                {!isLoading && sites.length > 0 && (
                    <Grid
                        container
                        sx={{ justifyContent: "center", mt: 2, pb: 2 }}
                    >
                        <Button
                            size="large"
                            variant="contained"
                            sx={{ minWidth: { xs: "100%", sm: "25%" } }}
                            disabled={sites.length <= maxResults}
                            onClick={showMoreHandler}
                            startIcon={<KeyboardArrowDownIcon />}
                        >
                            Show more
                        </Button>
                    </Grid>
                )}
                {/*<Grid container xs={12} sx={{ justifyContent: "right" }}>
					<Link to="/submit">
						<Fab color="primary" aria-label="add">
							<AddIcon />
						</Fab>
					</Link>
				</Grid>*/}
            </Container>
        </React.Fragment>
    );
};

export default Sites;
