import React, { useContext, useState } from "react";
import {
    Grid,
    Container,
    Card,
    CircularProgress,
    Typography,
} from "@mui/material";
import { AuthContext } from "../contexts/auth-context";
import { useHttpClient } from "../hooks/http-hook";
import SubmitForm from "../components/SubmitForm";
import { useNavigate } from "react-router-dom";

const Submit = () => {
    const auth = useContext(AuthContext);
    const navigate = useNavigate();

    const [isLoading, setIsLoading] = useState(false);

    const [formState, setFormState] = useState({
        siteName: "",
        siteLat: "",
        siteLon: "",
        siteDescription: "",
        tradition: "",
        siteAge: "",
        ageError: "",
        labCode: "",
        reference: "",
    });

    const [errorMessage, setErrorMessage] = useState("");

    const { sendRequest } = useHttpClient();

    const handleClear = () => {
        setFormState({
            siteName: "",
            siteLat: "",
            siteLon: "",
            siteDescription: "",
            tradition: "",
            siteAge: "",
            ageError: "",
            labCode: "",
            reference: "",
            siteId: "",
        });
    };

    const handleSubmit = async () => {
        setErrorMessage("");
        const isValid =
            formState.siteName &&
            formState.siteLat &&
            formState.siteLon &&
            formState.tradition &&
            formState.siteAge &&
            formState.ageError &&
            formState.reference;
        if (!isValid) {
            setErrorMessage("Please fill out all required fields");
            return;
        }
        setIsLoading(true);
        // const url = "http://localhost:8000/sites/add";
        const url = "https://api.expandproj.org/sites/add";
        await sendRequest(
            url,
            "POST",
            JSON.stringify({
                ...formState,
                user: auth.userId,
                status: "pending",
            }),
            {
                "Content-Type": "application/json",
            }
        );
        setIsLoading(false);
        navigate("/auth?submitted=true");
    };

    const inputChangeHandler = (event) => {
        let inputId = event.target.id;
        let inputValue = event.target.value;
        if (inputId.includes("tradition")) {
            inputId = "tradition";
            inputValue = event.target.innerHTML;
        }
        setFormState((prev) => ({ ...prev, [inputId]: inputValue }));
    };

    return (
        <React.Fragment>
            <Grid container className="page-image"></Grid>
            <Grid container>
                <Container
                    sx={{ position: "relative", zIndex: 2, mt: 2 }}
                    maxWidth="md"
                >
                    <Card sx={{ p: 4, mb: 2 }}>
                        <SubmitForm
                            onSubmitButtonClick={handleSubmit}
                            onInputChange={inputChangeHandler}
                            formState={formState}
                            onClearButtonClick={handleClear}
                        />
                    </Card>
                    <Grid
                        container
                        sx={{ justifyContent: "center", mt: 2, mb: 2 }}
                    >
                        {isLoading && <CircularProgress />}
                    </Grid>
                    {errorMessage && (
                        <Typography sx={{ textAlign: "center", mb: 2 }}>
                            {errorMessage}
                        </Typography>
                    )}
                </Container>
            </Grid>
        </React.Fragment>
    );
};

export default Submit;
