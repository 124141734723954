import {
	createTheme,
	responsiveFontSizes,
	experimental_sx as sx,
} from "@mui/material/styles";
import { pink } from "@mui/material/colors";

let theme = createTheme({
	typography: {
		fontFamily: "Roboto, sans-serif",
		h5: {
			fontSize: "1.8rem",
		},
		body1: {
			fontSize: "1.1rem",
		},
		body2: {
			fontSize: "1rem",
		},
	},
	palette: {
		primary: {
			main: pink[400],
			dark: pink[500],
		},
		secondary: {
			main: "#ffffff",
			dark: "#ffffff",
		},
	},
	shadows: [
		"none",
		"none",
		...Array(23).fill("0px 0px 24px rgba(0, 0, 0, 0.08)"),
	],
	components: {
		MuiPaper: {
			styleOverrides: {
				root: sx({
					borderRadius: "1em",
				}),
			},
		},
		MuiCard: {
			styleOverrides: {
				root: sx({
					border: "solid",
					borderWidth: "1px",
					borderColor: "#f0f0f0",
					borderRadius: "1em",
				}),
			},
		},
		MuiButton: {
			styleOverrides: {
				root: sx({
					fontSize: "1rem",
				}),
			},
		},
		MuiSlider: {
			styleOverrides: {
				valueLabel: sx({
					background: "none",
					color: "#212121",
					padding: 0,
					mt: 1,
				}),
			},
		},
	},
});

theme = responsiveFontSizes(theme);

export { theme };
