import { Card, CardActionArea, CardContent, CardMedia, Grid, Typography } from "@mui/material";
import { AnimationOnScroll } from 'react-animation-on-scroll';
import { pink } from "@mui/material/colors";

const MenuCard = props => {
    return (
        <Grid item xs={12} sm={6} md={4}>
            <AnimationOnScroll
                animateIn="animate__fadeIn"
                duration={0.5}
                animateOnce={true}
            >
                <Card
                    sx={{
                        minHeight: { sm: "510px" },
                        "&:hover": { backgroundColor: pink[400], color: "white" }
                    }}
                >
                    <CardActionArea
                        component="a"
                        href={props.sub}
                        target="_blank"
                    >
                        <CardMedia
                            component="img"
                            height="140"
                            image={require("../img/" + props.img)}
                        />
                        <CardContent className="home-card" sx={{ p: 2 }}>
                            <Typography
                                variant="h6"
                                sx={{ fontWeight: "bold" }}
                            >
                                {props.title}
                            </Typography>
                            <Typography
                                variant="body2"
                                sx={{ mt: 1, mb: 1, fontSize: "0.8rem" }}
                            >
                                {props.sub}
                            </Typography>
                            <Typography variant="body2">
                                {props.text}
                            </Typography>
                        </CardContent>
                    </CardActionArea>
                </Card>
            </AnimationOnScroll>
        </Grid>
    );
}

export default MenuCard;