import {
    Autocomplete,
    Box,
    Button,
    Grid,
    Slider,
    TextField,
    Typography,
} from "@mui/material";

import { TRADITIONS } from "./Traditions";
import { Link } from "react-router-dom";
import AddIcon from "@mui/icons-material/Add";

const Form = (props) => {
    return (
        <Grid container spacing={1.5}>
            <Grid item xs={12}>
                <Typography variant="h5" sx={{ fontWeight: "bold" }}>
                    C14 Database
                </Typography>
            </Grid>
            <Grid item xs={12}>
                <TextField
                    variant="outlined"
                    size="small"
                    label="Site name"
                    id="siteName"
                    onChange={props.onInputChange}
                    value={props.formState.siteName}
                    sx={{ minWidth: "100%" }}
                />
            </Grid>
            <Grid item xs={12} sx={{ mb: 2 }}>
                <Autocomplete
                    variant="outlined"
                    size="small"
                    sx={{ minWidth: "100%" }}
                    id="tradition"
                    options={TRADITIONS}
                    onInputChange={props.onInputChange}
                    renderInput={(params) => (
                        <TextField {...params} label="Cultural affiliation" />
                    )}
                />
            </Grid>
            <Grid item xs={5} sm={4}>
                <Typography>Date range BP</Typography>
            </Grid>
            <Grid item xs={7} sm={8}>
                <Box sx={{ width: "95%" }}>
                    <Slider
                        min={0}
                        max={6000}
                        step={1000}
                        valueLabelDisplay="on"
                        value={[
                            parseInt(props.formState.minAge),
                            parseInt(props.formState.maxAge),
                        ]}
                        onChange={props.onSliderChange}
                        marks
                        disableSwap
                    />
                </Box>
            </Grid>

            <Grid item xs={12} sm={6}>
                <Button
                    variant="outlined"
                    sx={{ minWidth: "100%" }}
                    onClick={props.onButtonClick}
                >
                    Clear
                </Button>
            </Grid>
            <Grid item xs={12} sm={6}>
                <Button
                    variant="contained"
                    onClick={props.onSubmit}
                    sx={{ minWidth: "100%" }}
                >
                    Search
                </Button>
            </Grid>
            <Grid item xs={12}>
                <Link to="/submit" style={{ textDecoration: "none" }}>
                    <Button
                        variant="outlined"
                        sx={{ minWidth: "100%" }}
                        startIcon={<AddIcon />}
                    >
                        Submit new date
                    </Button>
                </Link>
            </Grid>
        </Grid>
    );
};

export default Form;
