import {
    Autocomplete,
    Button,
    Grid,
    TextField,
    Typography,
} from "@mui/material";

import { TRADITIONS } from "./Traditions";


const SubmitForm = (props) => {
    return (
        <Grid container spacing={1.5}>
            <Grid item xs={12}>
                <Typography variant="h5" sx={{ fontWeight: "bold" }}>
                    Submit a new date
                </Typography>
            </Grid>
            <Grid item xs={12}>
                <Typography variant="h6" sx={{ mt: 2 }}>
                    1. Basic site information
                </Typography>
            </Grid>
            <Grid item xs={12}>
                <TextField
                    variant="outlined"
                    size="small"
                    label="Site name *"
                    id="siteName"
                    value={props.formState.siteName}
                    onChange={props.onInputChange}
                    //value={props.formState.siteName}
                    sx={{ minWidth: "100%" }}
                />
            </Grid>
            <Grid item xs={12}>
                <TextField
                    variant="outlined"
                    size="small"
                    label="Site description"
                    id="siteDescription"
                    multiline
                    rows={2}
                    value={props.formState.siteDescription}
                    onChange={props.onInputChange}
                    //value={props.formState.siteName}
                    sx={{ minWidth: "100%" }}
                />
            </Grid>
            <Grid item xs={12}>
                <Autocomplete
                    variant="outlined"
                    size="small"
                    sx={{ minWidth: "100%" }}
                    id="tradition"
                    options={TRADITIONS}
                    onInputChange={props.onInputChange}
                    renderInput={(params) => (
                        <TextField {...params} label="Cultural affiliation *" />
                    )}
                />
            </Grid>
            <Grid item xs={12}>
                <Typography variant="h6" sx={{ mt: 2 }}>
                    2. Coordinates (decimal degrees)
                </Typography>
            </Grid>
            <Grid item xs={6}>
                <TextField
                    variant="outlined"
                    size="small"
                    label="Latitude *"
                    id="siteLat"
                    value={props.formState.siteLat}
                    onChange={props.onInputChange}
                    //value={props.formState.siteName}
                    sx={{ minWidth: "100%" }}
                />
            </Grid>
            <Grid item xs={6}>
                <TextField
                    variant="outlined"
                    size="small"
                    label="Longitude *"
                    id="siteLon"
                    value={props.formState.siteLon}
                    onChange={props.onInputChange}
                    //value={props.formState.siteName}
                    sx={{ minWidth: "100%" }}
                />
            </Grid>
            <Grid item xs={12}>
                <Typography variant="h6" sx={{ mt: 2 }}>
                    3. Radiocarbon date
                </Typography>
            </Grid>
            <Grid item xs={6}>
                <TextField
                    variant="outlined"
                    size="small"
                    label="C14 Age *"
                    id="siteAge"
                    value={props.formState.siteAge}
                    onChange={props.onInputChange}
                    //value={props.formState.siteName}
                    sx={{ minWidth: "100%" }}
                />
            </Grid>
            <Grid item xs={6}>
                <TextField
                    variant="outlined"
                    size="small"
                    label="C14 Error *"
                    id="ageError"
                    value={props.formState.ageError}
                    onChange={props.onInputChange}
                    //value={props.formState.siteName}
                    sx={{ minWidth: "100%" }}
                />
            </Grid>
            <Grid item xs={12}>
                <TextField
                    variant="outlined"
                    size="small"
                    label="Lab code"
                    id="labCode"
                    value={props.formState.labCode}
                    onChange={props.onInputChange}
                    //value={props.formState.siteName}
                    sx={{ minWidth: "100%" }}
                />
            </Grid>
            <Grid item xs={12}>
                <Typography variant="h6" sx={{ mt: 2 }}>
                    4. Source of information
                </Typography>
            </Grid>
            <Grid item xs={12}>
                <TextField
                    variant="outlined"
                    size="small"
                    label="Reference *"
                    id="reference"
                    multiline
                    rows={2}
                    value={props.formState.reference}
                    onChange={props.onInputChange}
                    //value={props.formState.siteName}
                    sx={{ minWidth: "100%" }}
                />
            </Grid>
            <Grid item xs={12} mb={2}>
                <Typography>
                    * Required fields
                </Typography>
            </Grid>
            <Grid item xs={12} sm={6}>
                <Button
                    variant="outlined"
                    sx={{ minWidth: "100%" }}
                    onClick={props.onClearButtonClick}
                >
                    Clear
                </Button>
            </Grid>
            <Grid item xs={12} sm={6}>
                <Button
                    variant="contained"
                    sx={{ minWidth: "100%" }}
                    onClick={props.onSubmitButtonClick}
                >
                    Submit
                </Button>
            </Grid>
        </Grid>
    );
};

export default SubmitForm;
