export const TRADITIONS = [
    "",
    "Aguapé",
    "Alaka",
    "Ananatuba",
    "Angelim",
    "Aratu",
    "Arauquinoid",
    "Araçatuba",
    "Bacabal",
    "Barrancoid",
    "Bañador",
    "Baños",
    "Caju",
    "Camani",
    "Canindé",
    "Casarabe",
    "Cerritos",
    "Chocolatal",
    "Corrugated",
    "Corumbiara",
    "Cosanga",
    "Cumancaya",
    "Gaván",
    "Goya-Malabrigo",
    "Grigotá",
    "Guará",
    "Guiarana",
    "Hachured-Zoned",
    "Ilha Dionísio",
    "Incised Rim",
    "Incised-Punctate",
    "Ipiamais",
    "Irobi",
    "Jamari",
    "Jasiaquiri",
    "Joari",
    "Jutaí",
    "Mangabal",
    "Maniva",
    "Maracá",
    "Marajoara",
    "Mazagão",
    "Mina",
    "Monou-Teri",
    "Moravia",
    "Muitzentza",
    "Natá",
    "Nazaratequi",
    "Necuima",
    "Osoid",
    "Pacacocha",
    "Pailón",
    "Pambay",
    "Pantanal",
    "Pantanal-Chaco",
    "Papeba",
    "Pastaza",
    "Pedra do Caboclo",
    "Periperi",
    "Pimenteira",
    "Pocó-Açutuba",
    "Polychrome",
    "Pondo Creek",
    "Pre-Barrancoid",
    "Pre-Marajoara",
    "Pre-Pocó-Açutuba",
    "Pre-Polychrome",
    "Pre-Tivacundo",
    "Proto-Tupiguarani",
    "Pré-Pocó-Açutuba",
    "Quinari",
    "Saladoid",
    "Salvaterra",
    "San Juan Polychrome",
    "Saracá",
    "Shakimu",
    "Sinimbu",
    "Taperinha",
    "Taquara/Itararé",
    "Teso",
    "Tivacundo",
    "Tumichucua",
    "Tupiguarani",
    "Tutishcainyo",
    "Una",
    "Unclassified",
    "Upano",
    "Uru",
    "Versalles",
    "Yaluz",
    "Yaunchu"
]