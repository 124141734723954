import { useState, cloneElement, useContext } from "react";
import { Link } from "react-router-dom";
import {
    AppBar,
    Box,
    Button,
    Container,
    IconButton,
    Menu,
    MenuItem,
    Toolbar,
    Typography,
    useScrollTrigger,
    Avatar,
} from "@mui/material";
import MenuIcon from "@mui/icons-material/Menu";
import AccountCircleIcon from "@material-ui/icons/AccountCircle";
import { AuthContext } from "../contexts/auth-context";
import { pink } from "@mui/material/colors";

const NavbarScroll = (props) => {
    const trigger = useScrollTrigger({
        disableHysteresis: true,
        threshold: 2,
    });

    return cloneElement(props.children, {
        elevation: trigger ? 2 : 0,
        style: {
            background: trigger ? "white" : "none",
            transition: trigger ? "0.3s" : "0.0s",
            color: trigger ? "black" : "white",
        },
    });
};

const Navbar = () => {
    const auth = useContext(AuthContext);

    const [anchorElNav, setAnchorElNav] = useState(null);

    const handleOpenNavMenu = (event) => {
        setAnchorElNav(event.currentTarget);
    };

    const handleCloseNavMenu = () => {
        setAnchorElNav(null);
    };

    const trigger = useScrollTrigger({
        disableHysteresis: true,
        threshold: 2,
    });

    const pages = [
        {
            name: "About",
            link: { pathname: "/", hash: "#about" },
        },
        {
            name: "People",
            link: { pathname: "/", hash: "#people" },
        },
        {
            name: "Outcomes",
            link: { pathname: "/", hash: "#outcomes" },
        },
        {
            name: "Database",
            link: "database",
        },
    ];

    return (
        <NavbarScroll>
            <AppBar position="fixed" sx={{ borderRadius: 0 }}>
                <Container maxWidth={false} sx={{ ml: 0, mr: 0, p: 1 }}>
                    <Toolbar disableGutters>
                        <Box
                            component="a"
                            href="/"
                            sx={{
                                mr: 1,
                                mt: "auto",
                                mb: "auto",
                                filter: trigger ? "none" : "invert(1)",
                            }}
                        >
                            <img src={require("../img/logo.png")} height={32} />
                        </Box>
                        <Typography
                            component="a"
                            href="/"
                            variant="h5"
                            sx={{
                                mr: 1,
                                mt: "auto",
                                mb: 1.6,
                                fontFamily: "Roboto Condensed",
                                textDecoration: "none",
                                color: trigger ? "#212121" : "white",
                            }}
                        >
                            EXPAND
                        </Typography>

                        <Box
                            sx={{
                                flexGrow: 1,
                                display: { xs: "none", md: "flex" },
                            }}
                            justifyContent="end"
                        >
                            {pages.map((item) => (
                                <Button
                                    key={item.name}
                                    component={Link}
                                    to={item.link}
                                    size="large"
                                    sx={{
                                        color:
                                            item.name === "Database"
                                                ? trigger
                                                    ? "white"
                                                    : "black"
                                                : trigger
                                                  ? "black"
                                                  : "white",
                                        transition: trigger ? "0.3s" : "0.0s",
                                        background:
                                            item.name === "Database"
                                                ? trigger
                                                    ? "black"
                                                    : "white"
                                                : "none",
                                        "&:hover": {
                                            color: trigger ? "black" : "white",
                                            background: !trigger && "none",
                                        },
                                        ml: item.name === "Database" ? 2 : 0,
                                        pl: 2,
                                        pr: 2,
                                    }}
                                >
                                    {item.name}
                                </Button>
                            ))}
                            <IconButton
                                component={Link}
                                to="/auth"
                                sx={{ color: "inherit", ml: 2 }}
                            >
                                {(auth.isLoggedIn && auth.username && (
                                    <Avatar sx={{ bgcolor: pink[400] }}>
                                        {auth.username.slice(0, 1)}
                                    </Avatar>
                                )) || (
                                    <AccountCircleIcon
                                        style={{ fontSize: 40 }}
                                    />
                                )}
                            </IconButton>
                        </Box>

                        <Box
                            sx={{
                                flexGrow: 1,
                                display: { xs: "flex", md: "none" },
                            }}
                            justifyContent="end"
                        >
                            <IconButton
                                sx={{ color: "inherit" }}
                                onClick={handleOpenNavMenu}
                            >
                                <MenuIcon fontSize="large" />
                            </IconButton>
                            <Menu
                                anchorEl={anchorElNav}
                                keepMounted
                                transformOrigin={{
                                    vertical: "top",
                                    horizontal: "left",
                                }}
                                open={Boolean(anchorElNav)}
                                sx={{ display: { xs: "block", md: "none" } }}
                                onClose={handleCloseNavMenu}
                            >
                                {pages.map((item) => (
                                    <MenuItem
                                        key={item.name}
                                        component={Link}
                                        to={item.link}
                                        sx={{ "&:hover": { color: "black" } }}
                                        onClick={handleCloseNavMenu}
                                    >
                                        {item.name}
                                    </MenuItem>
                                ))}
                                <MenuItem
                                    component={Link}
                                    to={"auth"}
                                    sx={{ "&:hover": { color: "black" } }}
                                    onClick={handleCloseNavMenu}
                                >
                                    {(auth.isLoggedIn && "Account") || "Login"}
                                </MenuItem>
                            </Menu>
                        </Box>
                    </Toolbar>
                </Container>
            </AppBar>
        </NavbarScroll>
    );
};

export default Navbar;
