import React, { useState } from "react";
import {
    Avatar,
    Chip,
    Grid,
    ListItem,
    ListItemAvatar,
    ListItemText,
    Typography,
} from "@mui/material";
import ImageIcon from "@mui/icons-material/Image";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import ExpandLessIcon from "@mui/icons-material/ExpandLess";
import AddIcon from "@mui/icons-material/Add";
import RemoveIcon from "@mui/icons-material/Remove";
import AssessmentIcon from "@mui/icons-material/Assessment";
import pink from "@mui/material/colors/pink";

const Site = (props) => {
    const [isExpanded, setIsExpanded] = useState(false);

    const toggleExpansion = () => {
        setIsExpanded(!isExpanded);
    };

    return (
        <React.Fragment>
            <Grid
                container
                //onClick={toggleExpansion}
                //sx={{
                //    cursor: "pointer",
                //    "&:hover": { backgroundColor: "#fafafa" },
                //}}
                pl={2}
                pr={2}
            >
                <Grid item xs={10}>
                    <ListItem alignItems="flex-start">
                        <ListItemAvatar>
                            <Avatar>
                                <ImageIcon />
                            </Avatar>
                        </ListItemAvatar>
                        <ListItemText
                            primary={
                                <React.Fragment>
                                    <Typography
                                        variant="h6"
                                        sx={{ fontWeight: "bold" }}
                                    >
                                        {props.site.Site}
                                        {props.site.Status && (
                                            <Chip
                                                label={props.site.Status}
                                                style={{
                                                    marginLeft: "16px",
                                                    fontWeight: "normal",
                                                }}
                                            />
                                        )}
                                    </Typography>
                                    <Typography variant="body1">
                                        {props.site.Tradition}{" "}
                                        {props.site.Sub &&
                                            `(` + props.site.Sub + `)`}
                                    </Typography>
                                </React.Fragment>
                            }
                            secondary={
                                <React.Fragment>
                                    <Typography
                                        variant="body2"
                                        sx={{ color: "#212121" }}
                                    >
                                        {props.site.C14Age} +/-{" "}
                                        {props.site.C14SD} <sup>14</sup>C BP{" "}
                                        {props.site.LabNumber &&
                                            `(${props.site.LabNumber})`}
                                    </Typography>
                                    {isExpanded && (
                                        <React.Fragment>
                                            {props.site.SiteDescription && (
                                                <Typography
                                                    variant="body2"
                                                    sx={{
                                                        color: "#212121",
                                                        mt: 1,
                                                    }}
                                                >
                                                    <strong>
                                                        Description:{" "}
                                                    </strong>
                                                    {props.site.SiteDescription}
                                                </Typography>
                                            )}
                                            {props.site.Comments && (
                                                <Typography
                                                    variant="body2"
                                                    sx={{
                                                        color: "#212121",
                                                        mt: 1,
                                                    }}
                                                >
                                                    <strong>Comments: </strong>
                                                    {props.site.Comments}
                                                </Typography>
                                            )}
                                            <Typography
                                                variant="body2"
                                                sx={{
                                                    color: "#212121",
                                                    mt: 1,
                                                }}
                                            >
                                                <strong>Reference: </strong>
                                                {props.site.FullReference}
                                            </Typography>
                                            <Typography
                                                onClick={
                                                    props.handleCalibration
                                                }
                                                variant="body2"
                                                sx={{
                                                    color: pink[400],
                                                    mt: 1,
                                                    cursor: "pointer",
                                                }}
                                            >
                                                <AssessmentIcon
                                                    sx={{
                                                        color: pink[400],
                                                        mr: 1,
                                                        verticalAlign: "middle",
                                                        pb: "4px",
                                                    }}
                                                />
                                                Calibrated
                                            </Typography>
                                        </React.Fragment>
                                    )}
                                </React.Fragment>
                            }
                        />
                    </ListItem>
                </Grid>
                <Grid
                    item
                    xs={2}
                    display="flex"
                    justifyContent="flex-end"
                    alignItems="flex-start"
                >
                    {(isExpanded && (
                        <RemoveIcon
                            onClick={toggleExpansion}
                            sx={{ cursor: "pointer", marginTop: "16px" }}
                        />
                    )) || (
                        <AddIcon
                            onClick={toggleExpansion}
                            sx={{ cursor: "pointer", marginTop: "16px" }}
                        />
                    )}
                </Grid>
            </Grid>
        </React.Fragment>
    );
};

export default Site;
