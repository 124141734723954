import React from "react";
import { List, Card, Divider } from "@mui/material";

import Site from "./Site";

const SiteList = (props) => {
    return (
        <Card>
            <List>
                {props.sites.map((site, index) => (
                    <React.Fragment key={index}>
                        <Site site={site} id={index} handleCalibration={() => props.handleCalibration(site)} />
                        {index !== props.sites.length - 1 && (
                            <Divider variant="inset" component="li" />
                        )}
                    </React.Fragment>
                ))}
            </List>
        </Card>
    );
};

export default SiteList;
