import React, { useContext, useEffect, useState } from "react";
import {
    Avatar,
    Grid,
    Container,
    Card,
    Fade,
    TextField,
    Typography,
    Button,
    Modal,
} from "@mui/material";
import { AuthContext } from "../contexts/auth-context";
import SiteList from "../components/SiteList";
import LogoutIcon from "@mui/icons-material/Logout";
import SearchIcon from "@mui/icons-material/Search";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import AddIcon from "@mui/icons-material/Add";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import { Link, useLocation, useSearchParams } from "react-router-dom";
import { pink } from "@mui/material/colors";
import { useHttpClient } from "../hooks/http-hook";
import DateModal from "../components/DateModal";

const User = () => {
    const [searchParams, setSearchParams] = useSearchParams();
    const auth = useContext(AuthContext);
    // const location = useLocation();
    //
    const { sendRequest } = useHttpClient();

    const [userSites, setUserSites] = useState([]);
    const [filteredSites, setFilteredSites] = useState([]);
    const [showModal, setShowModal] = useState(false);

    const [maxResults, setMaxResults] = useState(5);

    const [open, setOpen] = useState(false);
    const [calData, setCalData] = useState(null);
    const [calInfo, setCalInfo] = useState(null);
    const [calSite, setCalSite] = useState(null);
    const [visitedSites, setVisitedSites] = useState({});

    const showMoreHandler = () => {
        setMaxResults((prev) => prev + 5);
    };

    useEffect(() => {
        //const searchParams = new URLSearchParams(location.search);
        if (searchParams.has("submitted")) {
            setShowModal(true);
            setTimeout(() => {
                setShowModal(false);
                searchParams.delete("submitted");
                setSearchParams(searchParams);
            }, 1000);
        }
    }, [searchParams, setSearchParams]);

    const handleSearch = (e) => {
        if (!userSites) {
            return;
        }
        if (e.target.value === "") {
            setFilteredSites(userSites);
            return;
        }
        const searchValue = e.target.value.toLowerCase();
        const filtered = userSites.filter((site) => {
            return (
                site.Site.toLowerCase().includes(searchValue) ||
                site.SiteDescription.toLowerCase().includes(searchValue) ||
                site.Tradition.toLowerCase().includes(searchValue)
            );
        });
        setFilteredSites(filtered);
    };

    const logoutHandler = () => {
        auth.logout();
    };

    useEffect(() => {
        const fetchUserSites = async () => {
            if (!auth.userId) {
                return;
            }
            try {
                const response = await fetch(
                    // `http://localhost:8000/users/sites/${auth.userId}`
                    `https://api.expandproj.org/users/sites/${auth.userId}`
                );
                const responseData = await response.json();
                setUserSites(responseData.sites);
                setFilteredSites(responseData.sites);
            } catch (err) {
                console.log(err);
            }
        };
        fetchUserSites();
    }, [auth.userId]);

    const handleCalibration = async (site) => {
        setCalData(null);
        setCalInfo(null);
        setCalSite(site);

        setOpen(true);

        if (visitedSites[site._id]) {
            setCalData(visitedSites[site._id].calData);
            setCalInfo(visitedSites[site._id].calInfo);
            return;
        }

        // let url = new URL("http://localhost:8000/dates/calibrate");
        let url = new URL("https://api.expandproj.org/dates/calibrate");
        url.searchParams.append("c14age", site.C14Age);
        url.searchParams.append("c14sd", site.C14SD);

        const responseData = await sendRequest(url.toString(), "GET", null, {
            "Content-Type": "application/json",
        });

        const calDate = responseData.calDate;
        setCalInfo(responseData.hpdData.intervals);

        let newCalData = {
            labels: calDate.map((row) => row[0]),
            datasets: [
                {
                    label: "Calibrated Date",
                    data: calDate.map((row) => row[1]),
                    fill: false,
                    borderColor: pink[400],
                    borderWidth: 2,
                    pointRadius: 0,
                },
                {
                    label: "95% probability",
                    data: responseData.hpdData.prDens.map((row) => row[1]),
                    fill: true,
                    backgroundColor: "rgba(255, 99, 132, 0.2)",
                    borderWidth: 0,
                    pointRadius: 0,
                },
            ],
        };

        setCalData(newCalData);

        setVisitedSites((prev) => ({
            ...prev,
            [site._id]: {
                calData: newCalData,
                calInfo: responseData.hpdData.intervals,
            },
        }));
    };

    return (
        <React.Fragment>
            <DateModal
                open={open}
                handleClose={() => setOpen(false)}
                calData={calData}
                calInfo={calInfo}
                site={calSite}
            />
            <Modal open={showModal}>
                <Fade in={showModal} timeout={500}>
                    <Card
                        sx={{
                            position: "absolute",
                            mt: "50vh",
                            ml: "50vw",
                            transform: "translate(-50%,-50%)",
                            p: 4,
                            borderRadius: 1,
                        }}
                    >
                        <Grid container>
                            <Grid item xs={12} textAlign="center">
                                <CheckCircleIcon
                                    sx={{ color: "green", fontSize: "32px" }}
                                />
                                <Typography>
                                    Thank you for your submission!
                                </Typography>
                            </Grid>
                        </Grid>
                    </Card>
                </Fade>
            </Modal>
            <Grid container className="page-image"></Grid>
            <Grid container>
                <Container sx={{ position: "relative", zIndex: 2, mt: 2 }}>
                    <Card sx={{ p: 4 }}>
                        <Grid container>
                            <Grid item xs={12}>
                                <Avatar
                                    sx={{
                                        width: 128,
                                        height: 128,
                                        fontSize: 96,
                                        marginX: "auto",
                                        mb: 2,
                                    }}
                                >
                                    {auth.username && auth.username.slice(0, 1)}
                                </Avatar>
                            </Grid>
                            <Grid item xs={12} textAlign="center">
                                <Typography variant="h6">
                                    Welcome back,
                                </Typography>
                            </Grid>
                            <Grid item xs={12} textAlign="center">
                                <Typography variant="h3">
                                    {auth.username}
                                </Typography>
                            </Grid>
                            <Grid item xs={12} textAlign="center">
                                <Typography variant="h6">
                                    {auth.userEmail}
                                </Typography>
                            </Grid>
                            <Grid container mt={2} spacing={2}>
                                <Grid item xs={12} sm={6}>
                                    <Link to="/submit" style={{ textDecoration: "none" }}>
                                        <Button
                                            variant="outlined"
                                            sx={{
                                                minWidth: "100%"
                                            }}
                                            startIcon={<AddIcon />}
                                        >
                                            Submit new date
                                        </Button>
                                    </Link>
                                </Grid>

                                <Grid item xs={12} sm={6} textAlign="center">
                                    <Button
                                        sx={{ minWidth: "100%" }}
                                        onClick={logoutHandler}
                                        startIcon={<LogoutIcon />}
                                    >
                                        Logout
                                    </Button>
                                </Grid>
                            </Grid>
                        </Grid>
                    </Card>
                </Container>
                {userSites && userSites.length > 0 && filteredSites && (
                    <Container>
                        <Grid container sx={{ my: 4 }} spacing={2}>
                            <Grid item xs={12} sm={6}>
                                <Typography variant="h5">
                                    Your Submissions
                                </Typography>
                            </Grid>
                            <Grid item xs={12} sm={6}>
                                <TextField
                                    InputProps={{
                                        startAdornment: <SearchIcon />,
                                    }}
                                    fullWidth
                                    size="small"
                                    label="Search"
                                    onChange={(e) => handleSearch(e)}
                                />
                            </Grid>
                        </Grid>
                        <>
                            <SiteList
                                handleCalibration={handleCalibration}
                                sites={filteredSites.slice(0, maxResults)}
                            />
                            <Grid item xs={12} textAlign="center" mt={2} pb={2}>
                                <Button
                                    size="large"
                                    variant="contained"
                                    sx={{ minWidth: { xs: "100%", sm: "25%" } }}
                                    disabled={
                                        filteredSites.length <= maxResults
                                    }
                                    onClick={showMoreHandler}
                                    startIcon={<KeyboardArrowDownIcon />}
                                >
                                    Show more
                                </Button>
                            </Grid>
                        </>
                    </Container>
                )}
            </Grid>
        </React.Fragment>
    );
};

export default User;
