import React, { useState, useContext } from "react";
import { pink } from "@mui/material/colors";
import {
    Button,
    Card,
    CircularProgress,
    Container,
    Grid,
    Typography,
    TextField,
} from "@mui/material";

import { useHttpClient } from "../hooks/http-hook";
import { AuthContext } from "../contexts/auth-context";
import { jwtDecode } from "jwt-decode";

const Auth = () => {
    const auth = useContext(AuthContext);

    const [isLoginMode, setLoginMode] = useState(true);
    const [formState, setFormState] = useState({});
    const [errorMessage, setErrorMessage] = useState("");
    const [isLoading, setIsLoading] = useState(false);

    const { sendRequest } = useHttpClient();

    const switchModeHandler = () => {
        setLoginMode(!isLoginMode);
    };

    const inputChangeHandler = (event) => {
        setFormState({
            ...formState,
            [event.target.id]: event.target.value,
        });
    };

    const authSubmitHandler = async (event) => {
        event.preventDefault();
        setErrorMessage("");
        if (isLoginMode) {
            const isValid = formState.email && formState.password;
            if (!isValid) {
                setErrorMessage("Please fill in all fields");
                return;
            }
            setIsLoading(true);
            try {
                const responseData = await sendRequest(
                    //"http://localhost:8000/users/login",
                    "https://api.expandproj.org/users/login",
                    "POST",
                    JSON.stringify({
                        email: formState.email,
                        password: formState.password,
                    }),
                    {
                        "Content-Type": "application/json",
                    }
                );
                auth.login(
                    responseData.userId,
                    responseData.username,
                    responseData.userEmail,
                    responseData.token
                );
            } catch (err) {
                const errorResponse =
                    err.message || "Something went wrong, please try again.";
                setErrorMessage(errorResponse);
            }
            setIsLoading(false);
        } else {
            const isValid =
                formState.name &&
                formState.email &&
                formState.password &&
                formState.confirmPassword;
            if (!isValid) {
                setErrorMessage("Please fill in all required fields");
                return;
            }
            if (formState.password !== formState.confirmPassword) {
                setErrorMessage("Passwords do not match");
                return;
            }
            setIsLoading(true);
            try {
                const responseData = await sendRequest(
                    // "http://localhost:8000/users/signup",
                    "https://api.expandproj.org/users/signup",
                    "POST",
                    JSON.stringify({
                        name: formState.name,
                        email: formState.email,
                        organization: formState.organization,
                        password: formState.password,
                    }),
                    {
                        "Content-Type": "application/json",
                    }
                );
                auth.login(
                    responseData.userId,
                    responseData.username,
                    responseData.userEmail,
                    responseData.token
                );
            } catch (err) {
                const errorResponse =
                    err.message || "Something went wrong, please try again.";
                setErrorMessage(errorResponse);
            }
            setIsLoading(false);
        }
    };

    return (
        <React.Fragment>
            <Grid container className="page-image"></Grid>
            <Container
                maxWidth="sm"
                sx={{ position: "relative", zIndex: 2, mt: 2 }}
            >
                <Card sx={{ p: 4 }}>
                    <Grid container spacing={2}>
                        <Grid item xs={12}>
                            <Typography
                                variant="h5"
                                sx={{ fontWeight: "bold" }}
                            >
                                {(isLoginMode && "Log In") || "Sign Up"}
                            </Typography>
                        </Grid>
                        {!isLoginMode && (
                            <Grid item xs={12}>
                                <TextField
                                    variant="outlined"
                                    size="small"
                                    label={"Name *"}
                                    id="name"
                                    sx={{ minWidth: "100%" }}
                                    onChange={inputChangeHandler}
                                />
                            </Grid>
                        )}
                        {!isLoginMode && (
                            <Grid item xs={12}>
                                <TextField
                                    variant="outlined"
                                    size="small"
                                    label="Organization"
                                    id="organization"
                                    sx={{ minWidth: "100%" }}
                                    onChange={inputChangeHandler}
                                />
                            </Grid>
                        )}
                        <Grid item xs={12}>
                            <TextField
                                variant="outlined"
                                size="small"
                                label={`Email ${isLoginMode ? "" : "*"}`}
                                id="email"
                                type="email"
                                sx={{ minWidth: "100%" }}
                                onChange={inputChangeHandler}
                            />
                        </Grid>
                        <Grid item xs={12}>
                            <TextField
                                variant="outlined"
                                size="small"
                                label={`Password ${isLoginMode ? "" : "*"}`}
                                id="password"
                                type="password"
                                sx={{ minWidth: "100%" }}
                                onChange={inputChangeHandler}
                            />
                        </Grid>
                        {!isLoginMode && (
                            <Grid item xs={12}>
                                <TextField
                                    variant="outlined"
                                    size="small"
                                    label="Confirm password *"
                                    id="confirmPassword"
                                    type="password"
                                    sx={{ minWidth: "100%" }}
                                    onChange={inputChangeHandler}
                                />
                            </Grid>
                        )}
                        {!isLoginMode && (
                            <Grid item xs={12}>
                                <Typography variant="subtitle1">
                                    * Required fields
                                </Typography>
                            </Grid>
                        )}
                        <Grid item xs={12}>
                            <Button
                                variant="contained"
                                color="primary"
                                sx={{ minWidth: "100%" }}
                                onClick={authSubmitHandler}
                            >
                                {(isLoginMode && "Log In") || "Sign Up"}
                            </Button>
                        </Grid>
                        <Grid item xs={12}>
                            <Typography
                                variant="subtitle1"
                                sx={{ textAlign: "center" }}
                            >
                                {(isLoginMode && "Don't have an account?") ||
                                    "Already have an account?"}
                                &nbsp;
                                <span
                                    style={{
                                        cursor: "pointer",
                                        color: pink[400],
                                    }}
                                    onClick={switchModeHandler}
                                >
                                    {(isLoginMode && "Sign Up") || "Log In"}
                                </span>
                            </Typography>
                        </Grid>
                        <Grid
                            item
                            xs={12}
                            justifyContent="center"
                            display="flex"
                        ></Grid>
                    </Grid>
                </Card>
                <Grid container sx={{ justifyContent: "center", mt: 2, mb: 2 }}>
                    {isLoading && <CircularProgress />}
                </Grid>
                {errorMessage && (
                    <Typography mt={2} sx={{ textAlign: "center" }}>
                        {errorMessage}
                    </Typography>
                )}
            </Container>
        </React.Fragment>
    );
};

export default Auth;
